import React, {useEffect,useState} from "react";
import Category from "./Category";

function Group(data: any) {
    const [language, setLanguage] = useState<"arabic" | "english">("arabic");

    useEffect(() => {
        setLanguage(data.language);
    }, [data.language]); 

    return (
        <div id={"group-id-"+data.data.id} className="menu">
            <h2 className="menu-group-heading">{language === "arabic" ? data.data.ar_name : data.data.en_name}</h2>
            <div className="menu-group-image-container">
                <img src={data.data.image} />
            </div>
           {data.data.categories.map((category: any) => {
            return <Category group={data.data} data={category} language={language} />
           })}
        </div>
    )
}

export default Group;