import React, {useState, useEffect} from "react";
import Item from "./Items/NormalItem";
import ShishaItem from "./Items/ShishaItem";

function Category(data: any) {
    const [language, setLanguage] = useState<"arabic" | "english">("arabic");

    useEffect(() => {
        setLanguage(data.language);
    }, [data.language]); 

    const normalGroupRender = () => {
        return (
            <div>
                <div className="menu-group-heading-container">
                    <h2 className="menu-group-category-heading">{language === "arabic" ? data.data.ar_name : data.data.en_name}</h2>
                </div>
                <div className="menu-group">
                    {data.data.items.map((item: any) => {
                        return <Item data={item} language={language} />
                    })}
                </div>
            </div>
        )
    }

    const shishaGroupRender = () => {
        return (
            <div>
                <div className="menu-group-heading-container">
                    <h2 className="menu-group-category-heading">{language === "arabic" ? data.data.ar_name : data.data.en_name}</h2>
                </div>
                <div style={{width: "100%", display: "flex", textAlign: "left", justifyContent: "center" }}>
                    <div className="menu-group-shisha">
                        {data.data.items.map((item: any, index: any) => {
                            return <ShishaItem reactId={index} data={item} language={language} />
                        })}
                    </div>
                </div>
            </div>
        )
    }

    //TODO: Use this code to render different group style
    // if(data.group.en_name === "Shisha") {
    //     return shishaGroupRender();
    // } else {
    //    return normalGroupRender();
    // }   

    return normalGroupRender();
}

export default Category;