import React, { useState, useEffect } from 'react';
import './styles.css';
import logo from './pierre-logo.png';
import axios from "axios";
import Group from './Group';
import Spinner from '../Spinner/Spiner';

function QR() {

    const [loading, setLoading] = useState<boolean>(true);
    const [minimumTimePassed, setMinimumTimePassed] = useState<boolean>(false);
    const [menuData, setMenudata] = useState<any>([]);
    const [language, setLanguage] = useState<"arabic" | "english">("english");

    const sortMenuGroups = (menu: any[]) => {
        return menu.sort((a,b) => a.display_priority - b.display_priority);
    }


    useEffect(() => { 
        const timeoutId = setTimeout(() => {
            setMinimumTimePassed(true)
        }, 3000);
         // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        axios.get(
            "https://vendor.bite-qr.com/public/api/qr", {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'Content-Type,Authorization',
                    'Access-Control-Allow-Methods': 'POST, PUT, PATCH, GET, DELETE, OPTIONS',
                    'Access-Control-Allow-Credentials': true,
                    'X-Atlassian-Token': 'nocheck',
                }
            }
        )
        .then((res: any) => {
            setLoading(false);
            setMenudata(sortMenuGroups(res.data));
        });
    }, []);

    if (loading || !minimumTimePassed) {
        return (
            <div className="introduction">
                {/* <div className="LandingLogoContainer">
                    <img className="vendorLogoImage" src={logo} />
                </div> */}
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <Spinner />
                        <h2 style={{marginTop: "40px"}}>Loading your menu....</h2>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <main className={language === "arabic" ? 'container bodyRTL' : 'container bodyLTR'}>
                <div className="navigator">
                    <div className="buttons">
                        {menuData.map((groupData: any) => {
                            const id='#group-id-'+groupData.id;
                            // return <a href={"#group-id-"+groupData.id} className='noIOSFirefix'>
                            //     <div  className="navigatorButton">{language === "arabic" ? groupData.ar_name : groupData.en_name}</div>
                            // </a>
                            return <div className="navigatorButton" onClick={event =>  window.location.href=id}>{language === "arabic" ? groupData.ar_name : groupData.en_name}</div>
                            // return <Link to="/signup" className="btn btn-primary">Sign up</Link>
                        })}
                    </div>
                </div>
    
                <div className="introduction">
                    <div className="LandingLogoContainer">
                        <img className="vendorLogoImage" src={logo} />
                    </div>
                    <div className="LandingExperianceContainer">
                        {language==="arabic" 
                        ? (
                            <>
                                <h2>استمتع بالتجربة الأفضل، جرب قائمتنا ولا تنسى أن تشاركنا رايك</h2>
                            </>
                        ) : (
                            <h2>Try our menu and don't forget to give us your feedback.</h2>
                        )}
                        {/* <div className="languages">
                            <div className="buttons">
                                <button onClick={() => setLanguage("arabic")}>
                                    <span>&#9872;</span>
                                    <span className="LanguageSelectorLabel">الاستمرار باللغة العربية</span>
                                    <span>&#9872;</span>
                                </button>
                                <button onClick={() => setLanguage("english")}>
                                    <span>&#9872;</span>
                                    <span className="LanguageSelectorLabel">Continue in english</span>
                                    <span>&#9872;</span>
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <div className="noise">
                <span>Experiance the finest</span> and give us your feedback, It will help us improve our products and services to meet your needs. 
                </div> */}
                <div id="menus" className="menu-container">
                        {menuData.map((groupData: any) => {return <Group data={groupData} language={language} />})}
                    </div>
            </main>
        );
    }
}

export default QR;
