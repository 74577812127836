import React, {useEffect, useState} from "react";

function Item(data: any) {

    const [language, setLanguage] = useState<"arabic" | "english">("arabic");

    useEffect(() => {
        setLanguage(data.language);
    }, [data.language]); 


    return (
        <div className="menu-item">
            {/* <img
                src="https://dummyimage.com/600x400/000/fff"
                alt="Black Placeholder Image"
                className="menu-item-img"
            /> */}
            <div className="menu-item-text">
            <h3 className="menu-item-heading">
                <div className="menu-item-name-container">
                    <span className="menu-item-name">{data.data.ar_name}</span>
                    <span className="menu-item-name" style={{fontSize: "20px", margin: "5px auto", width: "100%"}}>{data.data.en_name}</span>
                </div>
                {data.data.type === "normal"
                ? (
                    <span className="menu-item-price">
                        {data.data.price}
                    </span>
                ) : (
                    <span className="menu-item-price">
                        {(() => {
                           const prices = data.data.type.split('*')
                           return <span>{prices[0]} - {prices[1]}</span>
                        })()}
                    </span>
                )}
            </h3>
            <div className="menu-item-desc-container">
                <p className="menu-item-desc">{data.data.en_description}</p>
                <p className="menu-item-desc" style={{direction: "rtl", textAlign: "left"}}>{data.data.ar_description}</p>
            </div>
            </div>
        </div>
    )
}

export default Item;